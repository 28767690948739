.card {
    background-color: #f9f9f9;
    border-radius: 10px;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .card-title {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .card-body h6 {
    margin-bottom: 0.5rem;
  }
  
  .card-body p {
    margin: 0.2rem 0;
  }